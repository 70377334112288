<template>
  <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
    <div class="uk-margin">
      <div uk-grid class="uk-grid-small">
        <div class="uk-inline uk-width-medium uk-margin-small-right">
          <multiselect
              v-model="meta.company_id"
              placeholder="Search by company"
              label="name"
              name="company"
              track-by="name"
              :options="company_options"
              :searchable="true"
              :loading='isFetching'
              :internal-search="false"
              :options-limit="50"
              @search-change="val => setCompanyValues(val)"
            >
            </multiselect>
        </div>
        <div class="uk-form-controls uk-width-small">
          <select class="uk-select" id="form-horizontal-select" v-model="meta.status" @change="(e) => changeMeta(e, 'status')">
            <option :value=null>Select status</option>
            <option :value=0>Pending</option>
            <option :value=1>Approved</option>
            <option :value=2>Rejected</option>
          </select>
        </div>
        <div class="uk-inline">
          <button style="width:100px; cursor:pointer; height:40px" class="uk-button-primary" @click="showModal">Create</button>
        </div>
      </div>
    </div>

    <div class="uk-card uk-card-default uk-margin">
      <div class="uk-overflow-auto">
        <table class="uk-table uk-table-small uk-table-divider uk-table-striped">
          <thead>
            <tr>
              <th class="uk-text-center">Office Name</th>
              <th class="uk-text-center">Job Name</th>
              <th class="uk-text-center">User Name</th>
              <th class="uk-text-center">Real Check-In</th>
              <th class="uk-text-center">Check-In Server</th>
              <th class="uk-text-center uk-width-small">Check-In Notes</th>
              <th class="uk-text-center">Real Check-Out</th>
              <th class="uk-text-center">Check-Out Server</th>
              <th class="uk-text-center uk-width-small">Check-Out Notes</th>
              <th class="uk-text-center">Status</th>
              <th class="uk-text-center uk-width-1-6">Action</th>
            </tr>
          </thead>
          <tbody v-if="attendances.docs.length>0" class="uk-table-middle uk-text-center ">
            <tr v-for="(attendance, i) in attendances.docs" :key="i">
              <td>{{attendance.company_office.name}}</td>
              <td>{{attendance.job.title}}</td>
              <td>{{attendance.user.fullname}}</td>
              <td>{{formatDatetime(attendance.clock_in, true)}}</td>
              <td>{{formatDatetime(attendance.clock_in_server, true)}}</td>
              <td>{{attendance.clock_in_notes}}</td>
              <td>{{formatDatetime(attendance.clock_out, true)}}</td>
              <td>{{formatDatetime(attendance.clock_out_server, true)}}</td>
              <td>{{attendance.clock_out_notes}}</td>
              <td>
                <div
                  class="label uk-margin-auto"
                  :style="`width: 90px; background-color: ${attendance.status === 0 ? '#ff8000': attendance.status === 1 ? '#00cc00' : '#e63c3c'}`">
                  {{attendance.status === 0 ? 'Pending' : attendance.status === 1 ? 'Approved' : 'Rejected'}}
                </div>
              </td>
              <td>
                <button
                  style="width:75px; cursor:pointer; height:30px"
                  class="uk-button-primary uk-margin-small-right"
                  @click="showDetail(attendance)">
                  Log Detail
                </button>
                <button
                  style="width:75px; cursor:pointer; margin-bottom:5px; height:30px; background:#333747"
                  class="uk-button-primary"
                  v-clipboard:copy="attendance._id"
                  v-clipboard:success="onCopy">
                  Copy Id
                </button><br/>
                <button
                  style="width:75px; cursor:pointer; margin-bottom:5px; height:30px"
                  class="uk-button-default uk-margin-small-right"
                  @click="editUserAttendance(attendance)">
                  Edit
                </button>
                <button
                  v-if="isLoading"
                  style="width:75px; cursor:pointer; height:30px"
                  class="uk-button-danger" type="button" disabled>
                  <div uk-spinner></div>
                </button>
                <button
                  v-else
                  style="width:75px; cursor:pointer; height:30px"
                  class="uk-button-danger"
                  @click="showDeleteModal(attendance)">
                  Delete
                </button>
              </td>
            </tr>
          </tbody>
          <empty-table v-else :colspan="6" />
        </table>
      </div>
      <pagination
        :total-data="attendances.totalDocs"
        :change-limit="changeLimit"
        :change-page="changePage"
      />
    </div>

    <div id="attendanceDetailModal" uk-modal esc-close="false" bg-close="false">
      <div class="uk-modal-dialog">
        <button class="uk-modal-close-default" type="button" uk-close @click="hideDetail"></button>
        <div class="uk-modal-header">
          <h3 class="uk-modal-title">Timesheet Log Detail</h3>
        </div>
        <div class="uk-modal-body">
          <div class="uk-margin">
            <div v-if="timesheetLogs.length>0">
              <div v-for="(log, i) in timesheetLogs" :key="i">
                <h4>Check {{log.type === 'clock_in' ? 'In' : 'Out'}}</h4>
                <p v-if="log.scan_by">Scanned by <b>{{`${log.scan_by.fullname} (${log.scan_by.email})`}}</b></p>
                <p v-if="log.scan_by.ip">IP Address: <b>{{log.scan_by.ip}}</b></p>
                <p>On:
                  <b>{{formatDatetime(log.created_at, false)}}</b>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="uk-modal-footer uk-text-right">
          <button class="uk-button uk-button-primary" type="button" @click="hideDetail">OK</button>
        </div>
      </div>
    </div>

    <div id="formRoleModal" uk-modal esc-close="false" bg-close="false">
      <div class="uk-modal-dialog">
        <button class="uk-modal-close-default" type="button" uk-close @click="hideModal"></button>
        <div class="uk-modal-header">
          <h2 class="uk-modal-title">User Attendance Form</h2>
        </div>
        <div class="uk-modal-body">

          <div class="uk-margin">
            <label class="uk-form-label">Office (Property) <b class="uk-text-danger">*</b></label>
            <multiselect
              v-model="userAttendanceForm.company_office_id"
              placeholder="Type to search office"
              label="name"
              name="office"
              track-by="name"
              v-validate="'required'"
              :options="office_options"
              :searchable="true"
              :loading='isFetching'
              :internal-search="false"
              :options-limit="50"
              @search-change="setPropertyValues"
            >
            </multiselect>
            <span class="uk-text-small uk-text-danger" v-show="errors.has('office')">{{ errors.first('office') }}</span>
          </div>

          <div class="uk-margin">
            <label class="uk-form-label">Job <b class="uk-text-danger">*</b></label>
            <multiselect
              v-model="userAttendanceForm.job_id"
              placeholder="Type to search job"
              label="name"
              name="job"
              track-by="name"
              v-validate="'required'"
              :options="job_options"
              :searchable="true"
              :loading='isFetching'
              :internal-search="false"
              :options-limit="50"
              @search-change="setJobValues"
            >
            </multiselect>
            <span class="uk-text-small uk-text-danger" v-show="errors.has('job')">{{ errors.first('job') }}</span>
          </div>

          <div class="uk-margin">
            <label class="uk-form-label">User <b class="uk-text-danger">*</b></label>
            <multiselect
              v-model="userAttendanceForm.user_id"
              placeholder="Type to search user"
              label="name"
              name="user"
              track-by="name"
              v-validate="'required'"
              :options="user_options"
              :searchable="true"
              :loading='isFetching'
              :internal-search="false"
              :options-limit="50"
              @search-change="setUserValues"
              @select="item=>setJobPartnerValues(item.value)"
            >
            </multiselect>
            <span class="uk-text-small uk-text-danger" v-show="errors.has('user')">{{ errors.first('user') }}</span>
          </div>

          <div class="uk-margin">
            <label class="uk-form-label">Job Partner <b class="uk-text-danger">*</b></label>
            <multiselect
              v-model="userAttendanceForm.job_partner_id"
              placeholder="Select job partner (based on matching job and user)"
              label="name"
              name="job_partner"
              track-by="name"
              v-validate="'required'"
              :options="job_partner_options"
              :options-limit="50"
            >
            </multiselect>
            <span class="uk-text-small uk-text-danger" v-show="errors.has('job_partner')">{{ errors.first('job_partner') }}</span>
          </div>

          <div class="uk-margin">
            <label class="uk-form-label">Check-In Time</label>
            <datetime
              type="datetime"
              name="clock_in"
              v-model="userAttendanceForm.clock_in"
              placeholder="Check-In Time"
            ></datetime>
          </div>

          <div class="uk-margin">
            <label class="uk-form-label">Check-In Notes</label>
            <input
              class="uk-input"
              :class="{'uk-form-danger': errors.has('clock_in_notes')}"
              name="clock_in_notes"
              type="text"
              v-model="userAttendanceForm.clock_in_notes"
              placeholder="Check-In Notes">
          </div>

          <div class="uk-margin">
            <label class="uk-form-label">Check-Out Time</label>
            <datetime
              type="datetime"
              name="clock_out"
              v-model="userAttendanceForm.clock_out"
              placeholder="Check-Out Time"
            ></datetime>
          </div>

          <div class="uk-margin">
            <label class="uk-form-label">Check-Out Notes</label>
            <input
              class="uk-input"
              :class="{'uk-form-danger': errors.has('clock_out_notes')}"
              name="clock_out_notes"
              type="text"
              v-model="userAttendanceForm.clock_out_notes"
              placeholder="Check-Out Notes">
          </div>

          <div class="uk-margin">
            <label class="uk-form-label">Attendance Status <b class="uk-text-danger">*</b></label>
            <multiselect
              v-model="userAttendanceForm.status"
              placeholder="Select Status"
              label="name"
              name="attendance_status"
              track-by="name"
              v-validate="'required'"
              :options="status_options">
            </multiselect>
            <span class="uk-text-small uk-text-danger" v-show="errors.has('attendance_status')">{{ errors.first('attendance_status') }}</span>
          </div>

        </div>
        <div class="uk-modal-footer uk-text-right">
          <button v-if="isLoading" class="uk-button uk-button-primary" type="button" disabled><div uk-spinner></div></button>
          <button v-else class="uk-button uk-button-primary" type="button" @click="saveUserAttendance">Save</button>
        </div>
      </div>
    </div>

    <div id="deleteRoleModal" uk-modal esc-close="false" bg-close="false">
      <div class="uk-modal-dialog">
        <button class="uk-modal-close-default" type="button" uk-close @click="hideDeleteModal"></button>
        <div class="uk-modal-header">
          <h2 class="uk-modal-title">Delete User Attendance?</h2>
        </div>
        <div class="uk-modal-body">
          <div class="uk-margin">
            <div>
              <h4>Office Name:</h4>
              <p>{{userAttendanceForm.company_office_id.name}}</p>
            </div>
            <div>
              <h4>Job Name:</h4>
              <p>{{userAttendanceForm.job_id.name}}</p>
            </div>
            <div>
              <h4>User Name:</h4>
              <p>{{userAttendanceForm.user_id.name}}</p>
            </div>
            <div>
              <h4>Check-In Time:</h4>
              <p>{{formatDatetime(userAttendanceForm.clock_in, false)}}</p>
            </div>
            <div>
              <h4>Check-Out Time:</h4>
              <p>{{formatDatetime(userAttendanceForm.clock_out, false)}}</p>
            </div>
            <div>
              <h4>Status:</h4>
              <p>{{userAttendanceForm.status.name}}</p>
            </div>
          </div>
        </div>
        <div class="uk-modal-footer uk-text-right">
          <button v-if="isLoading" class="uk-button uk-button-primary" type="button" disabled><div uk-spinner></div></button>
          <button v-else class="uk-button uk-button-danger" type="button" @click="markRoleDeleted">Delete</button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Pagination from '@/components/globals/Pagination';
import EmptyTable from '@/components/globals/tables/EmptyTable';
import { mapActions, mapGetters } from 'vuex';
import {
    notificationSuccess,
    notificationDanger
} from '@/utils/notification';
import {Datetime} from 'vue-datetime';
import formatter from '@/utils/formatter';

export default {
    data() {
        return {
            meta: {
                limit: 100,
                page: 1,
                status: null,
                is_deleted: false,
                company_id: '',
            },
            timesheetLogs: [],
            userAttendanceForm: {
                company_office_id: '',
                job_partner_id: '',
                job_id: '',
                user_id: '',
                clock_in: null,
                clock_in_notes: null,
                clock_out: null,
                clock_out_notes: null,
                status: '',
            },
            formIsEdit: false,
            isLoading: false,
            status_options: [
                { name: 'Rejected', value: 2},
                { name: 'Approved', value: 1},
                { name: 'Pending', value: 0}
            ],
            office_options: [],
            job_options: [],
            user_options: [],
            job_partner_options: [],
            company_options: [],
            isFetching: false,
        };
    },
    components: {
        Pagination,
        EmptyTable,
        Datetime
    },
    computed: {
        ...mapGetters({
            attendances: 'user_attendance/attendances',
            properties: 'property/properties',
            jobs: 'job/jobs',
            users: 'user/users',
            job_partners: 'job_partner/job_partners',
            companies: 'company/companies',
        }),
        metaModifier() {return this.meta;}
    },
    watch: {
        metaModifier: {
            handler: function() {
                this.setUserAttendanceMeta();
            },
            deep:true
        }
    },
    mounted() {
        this.setUserAttendanceMeta();
    },
    methods: {
        ...mapActions({
            getAttendances: 'user_attendance/getAttendances',
            createAttendance: 'user_attendance/createAttendance',
            updateAttendance: 'user_attendance/updateAttendance',
            deleteAttendance: 'user_attendance/deleteAttendance',
            getProperties: 'property/getProperties',
            getJob: 'job/getJob',
            getUser: 'user/getUser',
            getJobPartners: 'job_partner/getJobPartners',
            getCompanies: 'company/getCompanies',
        }),
        async setPropertyValues(query) {
            this.isFetching = true;

            await this.getProperties({name: query});
            this.office_options = this.properties.docs.map(
                obj => ({name: obj.name, value: obj._id})
            );

            this.isFetching = false;
        },
        async setJobValues(query) {
            this.isFetching = true;

            await this.getJob({title: query});
            this.job_options = this.jobs.docs.map(
                obj => ({name: obj.title, value: obj._id})
            );

            this.isFetching = false;
        },
        async setUserValues(query) {
            this.isFetching = true;

            await this.getUser({fullname: query});
            this.user_options = this.users.docs.map(
                obj => ({name: `${obj.fullname} (${obj.email})`, value: obj._id})
            );

            this.isFetching = false;
        },
        async setJobPartnerValues(query) {
            this.isFetching = true;

            await this.getJobPartners({user_id: query});
            this.job_partner_options = this.job_partners.docs.map(
                obj => ({
                    name: `${obj.user.fullname} - (${obj.company.name}, ${obj.job.title})`,
                    value: obj._id
                })
            );

            this.isFetching = false;
        },
        async setCompanyValues(query) {
            this.isFetching = true;

            await this.getCompanies({name: query});
            this.company_options = this.companies.docs.map(
                obj => ({name: obj.name, value: obj._id})
            );

            this.isFetching = false;
        },
        setUserAttendanceMeta() {
            this.getAttendances({
                ...this.meta,
                company_id: this.meta.company_id.value
            });
        },
        changeMeta(e, prop) {
            this.meta = {
                ...this.meta,
                [prop]: e.target.value
            };
            this.getAttendances(this.meta);
        },
        changeLimit(e) {
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            this.meta = {
                ...this.meta,
                page: value
            };
        },
        formatDatetime(date, shortDate) {
            if (!date) return null;
            return shortDate ? formatter.datetimeShort(date) : formatter.datetimeComplete(date);
        },
        async saveUserAttendance() {
            try {
                const validate = await this.$validator.validateAll();
                if (!validate || this.$validator.errors.any()) return;

                this.isLoading = true;

                this.userAttendanceForm.company_office_id = this.userAttendanceForm.company_office_id.value;
                this.userAttendanceForm.job_id = this.userAttendanceForm.job_id.value;
                this.userAttendanceForm.user_id = this.userAttendanceForm.user_id.value;
                this.userAttendanceForm.job_partner_id = this.userAttendanceForm.job_partner_id.value;
                this.userAttendanceForm.status = this.userAttendanceForm.status.value;

                [
                    'clock_in', 'clock_out', 'clock_in_notes', 'clock_out_notes',
                ].forEach(prop => {
                    if (!this.userAttendanceForm[prop]) this.userAttendanceForm[prop] = null;
                });

                const response = this.formIsEdit
                    ? await this.updateAttendance(this.userAttendanceForm)
                    : await this.createAttendance(this.userAttendanceForm);

                this.isLoading = false;
                if (response && response.status === 'OK') {
                    notificationSuccess('User attendance saved!');
                    this.hideModal();
                    this.setUserAttendanceMeta();
                } else {
                    notificationDanger('Failed to save user attendance.');
                }
            } catch (err) {
                notificationDanger(err);
            }
        },
        async fillForm(attendance) {
            try {
                this.userAttendanceForm.id = attendance._id;
                [
                    'clock_in', 'clock_out',  'name',
                    'clock_in_notes', 'clock_out_notes',
                ].forEach(prop => {
                    this.userAttendanceForm[prop] = attendance[prop];
                });
                this.userAttendanceForm.status = this.status_options.find(
                    e => e.value === attendance.status
                );
                this.userAttendanceForm.company_office_id = {
                    name: attendance.company_office.name,
                    value: attendance.company_office._id
                };
                this.userAttendanceForm.job_id = {
                    name: attendance.job.title,
                    value: attendance.job._id
                };
                this.userAttendanceForm.user_id = {
                    name: `${attendance.user.fullname} (${attendance.user.email})`,
                    value: attendance.user._id
                };
                await this.setJobPartnerValues(attendance.user._id);
                this.userAttendanceForm.job_partner_id = this.job_partner_options.find(
                    e => e.value === attendance.job_partner._id
                );
            } catch (err) {
                notificationDanger(err);
            }
        },
        editUserAttendance(attendance) {
            this.formIsEdit = true;
            this.fillForm(attendance);
            this.showModal();
        },
        async markRoleDeleted() {
            try {
                this.isLoading = true;
                const response = await this.deleteAttendance(this.userAttendanceForm.id);
                this.isLoading = false;

                if (response && response.status === 'OK') {
                    notificationSuccess('User attendance deleted!');
                    this.hideDeleteModal();
                    this.setUserAttendanceMeta();
                } else {
                    notificationDanger('Failed to delete user attendance.');
                }
            } catch (err) {
                notificationDanger(err);
            }
        },
        async showDetail(attendance) {
            this.timesheetLogs = attendance.timesheet_log;
            await window.UIkit.modal('#attendanceDetailModal').show();
        },
        async hideDetail() {
            await window.UIkit.modal('#attendanceDetailModal').hide();
            this.timesheetLogs = [];
        },
        resetForm() {
            this.formIsEdit = false;
            this.userAttendanceForm = {
                company_office_id: '',
                job_partner_id: '',
                job_id: '',
                user_id: '',
                clock_in: null,
                clock_in_notes: null,
                clock_out: null,
                clock_out_notes: null,
                status: '',
            };
            this.$validator.reset();
        },
        async showDeleteModal(attendance) {
            this.fillForm(attendance);
            await window.UIkit.modal('#deleteRoleModal').show();
        },
        async hideDeleteModal() {
            await window.UIkit.modal('#deleteRoleModal').hide();
            this.resetForm();
        },
        async showModal() {
            await window.UIkit.modal('#formRoleModal').show();
        },
        async hideModal() {
            await window.UIkit.modal('#formRoleModal').hide();
            this.resetForm();
        },
        onCopy(e) {
            notificationSuccess('Copied to clipboard: ' + e.text);
        },
    }
};
</script>
